import { DataItemModel } from 'o365.modules.DataObject.Types.ts';
import { DataGridControl } from 'o365.controls.DataGrid.ts';
import { addEventListener } from 'o365.vue.composables.EventListener.ts';
import logger from 'o365.modules.Logger.ts';

Object.defineProperties(DataGridControl.prototype, {
    'rowDrag': {
        get() {
            if (this._rowDrag == null) {
                this._rowDrag = new DataGridRowDrag(this);
            }
            return this._rowDrag;
        }
    },
    'hasRowDrag': {
        get() {
            return !!this._rowDrag;
        },
        set (_pValue) {
            // reactivity fix
        }
    }
});

export default class DataGridRowDrag {
    static get TransferType() { return 'o365-nt/data-grid-row-drag'; }

    private _dataGridControl: DataGridControl;

    private _cleanupTokens: (() => void)[] = [];

    private _dragImage?: HTMLElement;
    private _dragIndicator?: HTMLElement;
    private _dragEndCt?: () => void;

    private _orderField?: string;
    private _step = 1000;
    private _uniqueField = 'key';
    private _dragMargin = 12;
    private _allowDragOver = false;
    // draggedRow, rowAbove, rowBelow, index, bottomEdge
    private _onAfterDrop?: ((pOptions: {
        row: DataItemModel,
        rowAbove?: DataItemModel,
        rowBelow?: DataItemModel,
        draggedOverIndex: number,
        dragPosition: DragRowPosition
    }) => void);

    get step() { return this._step; }
    get direction() { return true ? 'asc' : 'desc'; }
    get orderField() { return this._orderField; }

    constructor(pDataGridControl: DataGridControl) {
        this._dataGridControl = pDataGridControl;
    }

    enable(pOptions?: {
        uniqueField?: string,
        field?: string,
        step?: number,
        onAfterDrop?: DataGridRowDrag['_onAfterDrop'],
        dragMargin?: number,
        allowDragOver?: boolean,
    }) {
        this._cleanupTokens.splice(0, this._cleanupTokens.length).forEach(ct => ct());

        this._orderField = pOptions?.field ??  pOptions?.onAfterDrop ? undefined : 'SortOrder';
        if (pOptions?.step) {
            this._step = pOptions.step;
        }
        if (pOptions?.onAfterDrop) {
            this._onAfterDrop = pOptions.onAfterDrop;
        }
        if (pOptions?.uniqueField) {
            this._uniqueField = pOptions.uniqueField;
        }
        if (pOptions?.dragMargin) {
            this._dragMargin = pOptions.dragMargin
        }
        if (pOptions?.allowDragOver) {
            this._allowDragOver = pOptions.allowDragOver;
        }

        if (this._dataGridControl.container) {
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'dragstart', this._onDragStart.bind(this)));
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'dragover', this._onDragOver.bind(this)));
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'dragleave', this._onDragLeave.bind(this)));
            this._cleanupTokens.push(addEventListener(this._dataGridControl.container, 'drop', this._onDrop.bind(this)));
        }
    }

    dispose() {
        this._cleanupTokens.forEach(ct => ct());
        (this._dataGridControl as any)._rowDrag = null;
    }

    updateSortOrder(pRow: DataItemModel, pAboveRow: DataItemModel | undefined, pBelowRow: DataItemModel | undefined, pDisplayIndex: number, pBottomEdge: boolean) {
        if (this.orderField == null || (pAboveRow == null && pBelowRow == null)) { return; }

        let newSortOrder = 0;
        if (pAboveRow == null) {
            const below = this._datetimeToNumber(pBelowRow![this.orderField]);
            if (this.direction === 'asc') {
                newSortOrder = below - this.step;
            } else {
                newSortOrder = below + this.step;
            }
        } else if (pBelowRow == null) {
            const above = this._datetimeToNumber(pAboveRow![this.orderField]);
            if (this.direction === 'asc') {
                newSortOrder = above + this.step;
            } else {
                newSortOrder = above - this.step;
            }
        } else {
            const above = this._datetimeToNumber(pAboveRow[this.orderField]);
            const below = this._datetimeToNumber(pBelowRow[this.orderField]);
            if (above == below) {
                // TODO: sort orders match, need to find closet diffrent ones
                let aboveRowDisplayIndex = -1;
                let belowRowDisplayIndex = -1;
                if (pBottomEdge) {
                    // display index is from above row
                    aboveRowDisplayIndex = pDisplayIndex;
                    belowRowDisplayIndex = pDisplayIndex + 1;
                } else {
                    // display index is from below row 
                    aboveRowDisplayIndex = pDisplayIndex - 1;
                    belowRowDisplayIndex = pDisplayIndex;
                }

            }
            newSortOrder = (above + below) / 2
        }
        pRow![this.orderField] = new Date(newSortOrder);
    }

    updateRowLocation(pRow: DataItemModel, pAboveRow: DataItemModel | undefined, pBelowRow: DataItemModel | undefined, pDisplayIndex: number, pBottomEdge: boolean) {
        if (pAboveRow == null && pBelowRow == null) { return; }
        const data = this._getDataArray(true);

        let searchForAbove = true;
        if (pAboveRow == null) {
            searchForAbove = false;
        }
        let indexToInsert = -1;
        if (searchForAbove) {
            indexToInsert = data.findIndex(this._getRowPredicate(pAboveRow!)) + 1;
            // indexToInsert = data.findIndex(x => x.key === pAboveRow!.key) + 1;
        } else {
            indexToInsert = data.findIndex(this._getRowPredicate(pBelowRow!));
            // indexToInsert = data.findIndex(x => x.key === pBelowRow!.key);
        }
        if (indexToInsert > pRow.index) {
            indexToInsert -= 1;
        }


        // if (pBelowRow == null) {
        //     indexToInsert -= 1;
        // }

        // if (pBotomEdge) {
        //     indexToInsert += 1;
        // }

        const rowIndex = this._dataGridControl.dataObject
            ? pRow.index
            : this._dataGridControl.props.data?.findIndex(this._getRowPredicate(pRow as Partial<DataItemModel>));

        if (rowIndex == null || rowIndex === -1) { return; }
        if (this._dataGridControl.props.data && indexToInsert > rowIndex) {
            indexToInsert -= 1;
        }

        this._shiftItem(rowIndex, indexToInsert);
        // this._dataGridControl.dataObject!.reshiftItem(rowIndex, indexToInsert);
    }


    private _onDragStart(pEvent: DragEvent) {
        if (pEvent.dataTransfer == null) { return; }
        const rowHandle = (pEvent.target as HTMLElement)?.closest<HTMLElement>('.o365-rowhandle');
        if (rowHandle == null) { return; }

        const row = (pEvent.target as HTMLElement)?.closest<HTMLElement>('.o365-body-row');
        if (row == null) { return; }
        const rowIndex = +row.dataset.o365Rowindex!;
        const rowKey = rowHandle.dataset.o365DragKey!;

        pEvent.dataTransfer.effectAllowed = 'move';
        pEvent.dataTransfer.setData(DataGridRowDrag.TransferType, JSON.stringify({ index: +rowIndex, key: rowKey } as RowDragData));

        const dragImage = this._getDragImage((pEvent.target as HTMLElement));
        pEvent.dataTransfer.setDragImage(dragImage, 0, -15);
        this._dataGridControl.navigation.clearSelection();
        this._dataGridControl.navigation.clearFocus();

        this._dragEndCt = addEventListener(window, 'dragend', () => {
            this._clearDragImage();
            this._clearDragIndicator();
        }, { once: true });
    }

    private _onDragOver(pEvent: DragEvent) {
        const isDragItem = pEvent.dataTransfer?.types.includes(DataGridRowDrag.TransferType);
        if (!isDragItem) { return; }
        if (pEvent.dataTransfer == null) { return; }
        const [row, rowEl, _index, dragPosition] = this._getRowFromEvent(pEvent);
        if (row == null || rowEl == null) {
            pEvent.dataTransfer.dropEffect = 'none';
            if (!(pEvent.target as HTMLElement).classList.contains('o365-drag-indicator')) {
                this._clearDragIndicator();
            }
            return;
        }

        this._updateDragIndicator(rowEl, dragPosition!);
        pEvent.dataTransfer.dropEffect = 'move';
        pEvent.preventDefault();
    }

    private _onDragLeave(pEvent: DragEvent) {
        const isDragItem = pEvent.dataTransfer?.types.includes(DataGridRowDrag.TransferType);
        if (pEvent.dataTransfer == null) { return; }
        if (isDragItem) {
            const closest = (pEvent.target as HTMLElement)?.closest('.o365-body-row');
            if (closest == null) {
                pEvent.dataTransfer.dropEffect = 'none';
            }
        }
    }

    private _onDrop(pEvent: DragEvent) {
        try {
            const isDragItem = pEvent.dataTransfer?.types.includes(DataGridRowDrag.TransferType);
            if (pEvent.dataTransfer == null) { return; }
            if (!isDragItem) { return; }
            const [row, rowEl, index, dragPosition] = this._getRowFromEvent(pEvent);
            if (row == null || rowEl == null) {
                return;
            }
            const bottomEdge = positionIsBelow(dragPosition!);

            const json = pEvent.dataTransfer.getData(DataGridRowDrag.TransferType);
            const dragOptions: RowDragData = JSON.parse(json);
            const data = this._getDataArray();
            const draggedRow: DataItemModel = data[dragOptions.index] as any;

            const rowIndex = this._dataGridControl.dataObject
                ? row.index
                : data.findIndex(this._getRowPredicate(row));
            const draggedRowIndex = this._dataGridControl.dataObject
                ? draggedRow.index
                : data.findIndex(this._getRowPredicate(draggedRow));

            if (draggedRow == null || rowIndex == draggedRowIndex) { return; }

            let rowAbove: DataItemModel | undefined = undefined;
            let rowBelow: DataItemModel | undefined = undefined;
            if (bottomEdge) {
                // position between row and row below
                rowAbove = row as any;
                rowBelow = data[index! + 1] as any;
            } else {
                // position between row and row above
                rowAbove = data[index! - 1] as any;
                rowBelow = row as any;
            }
            if (dragPosition != 'over' && (draggedRow[this._uniqueField] === rowAbove?.[this._uniqueField] || draggedRow[this._uniqueField] === rowBelow?.[this._uniqueField])) { return; }

            this.updateSortOrder(draggedRow, rowAbove, rowBelow, index, bottomEdge);
            this.updateRowLocation(draggedRow, rowAbove, rowBelow, index, bottomEdge);
            if (this._onAfterDrop) {
                this._onAfterDrop({
                    row: draggedRow,
                    rowAbove: rowAbove,
                    rowBelow: rowBelow,
                    draggedOverIndex: index!,
                    dragPosition: dragPosition!
                });
            }
            if (this._dataGridControl.dataObject) {
                draggedRow.save();
            }
            window.dispatchEvent(new Event('resize'));
        } catch (ex) {
            logger.error(ex);
        } finally {
            this._clearDragImage();
            this._clearDragIndicator();
            if (this._dragEndCt) {
                this._dragEndCt();
                this._dragEndCt = undefined;
            }
        }
    }

    private _getDragImage(pElement: HTMLElement) {
        if (this._dragImage) { this._dragImage.remove(); }
        this._clearDragImage();
        const rowContainer = document.createElement('div');
        rowContainer.style.width = pElement.closest<HTMLElement>('.o365-body-center-viewport')!.clientWidth + 'px';
        rowContainer.className = 'o365-data-grid';
        rowContainer.style.height = '34px';
        const rowL = pElement.closest<HTMLElement>('.o365-body-row')!.cloneNode(true) as HTMLElement;
        this._cleanDragRowStyles(rowL, 'left');
        const rowIndex = +rowL.dataset.o365Rowindex!;
        let rowC = this._dataGridControl.container!.querySelector<HTMLElement>(`.o365-body-center-cols [data-o365-rowindex="${rowIndex}"]`)
        rowContainer.append(rowL);
        if (rowC) {
            rowC = rowC.cloneNode(true) as HTMLElement;
            this._cleanDragRowStyles(rowC);
            rowContainer.append(rowC);
        }
        let rowR = pElement.closest<HTMLElement>('.o365-body-right-pinned-cols [data-o365-rowindex="${rowIndex}"]');
        if (rowR) {
            rowR = rowR.cloneNode(true) as HTMLElement;
            this._cleanDragRowStyles(rowR, 'right');
            rowContainer.append(rowR);
        }

        if (this._dataGridControl.isTable) {
            rowContainer.classList.add('o365-data-table');
            rowL.classList.add('d-flex');
        }
        this._dragImage = rowContainer;
        this._dragImage.style.zIndex = '2000';
        this._dragImage.style.position = 'absolute';
        this._dragImage.style.left = '-9999px';
        this._dragImage.style.top = '-9999px';
        document.body.append(this._dragImage);
        return this._dragImage;
    }

    private _cleanDragRowStyles(pRow: HTMLElement, pPin?: 'left' | 'right') {
        pRow.style.transform = '';
        pRow.className = 'o365-body-row bg-body'
        switch (pPin) {
            case 'left':
                // pRow.style.width = this._dataGridControl.dataColumns.leftPinnedWidth + 'px';
                break;
            case 'right':
                // pRow.style.width = this._dataGridControl.dataColumns.rightPinnedWidth + 'px';
                pRow.style.right = '0px';
                break;
            default:
                // pRow.style.width = this._dataGridControl.dataColumns.centerWidth + 'px';
                pRow.style.left = this._dataGridControl.dataColumns.leftPinnedWidth + 'px';
                break;
        }
        pRow.querySelectorAll<HTMLElement>('.o365-body-cell').forEach(cell => {
            cell.className = 'o365-body-cell';
        });
    }

    private _clearDragImage() {
        if (this._dragImage) { this._dragImage.remove(); }
        this._dragImage = undefined;
    }

    private _clearDragIndicator() {
        if (this._dragIndicator) { this._dragIndicator.remove(); }
        document.querySelectorAll('.o365-dragover-indicator').forEach(el => el.classList.remove('o365-dragover-indicator'));
        this._dragIndicator = undefined;
    }

    private _updateDragIndicator(pRowEl: HTMLElement, pPosition: DragRowPosition) {
        const container = this._dataGridControl.container?.querySelector('.o365-body-center-viewport')
        if (container == null) { this._clearDragIndicator(); return; }
        document.querySelectorAll('.o365-dragover-indicator').forEach(el => el.classList.remove('o365-dragover-indicator'));
        if (pPosition === 'over') {
            const index = pRowEl.dataset.o365Rowindex
            this._dataGridControl.container?.querySelectorAll(this._dataGridControl._gridQuery(`.o365-body-center-viewport [data-o365-rowindex="${index}"]`)).forEach(el => {
                el.classList.add('o365-dragover-indicator');
            });
            if (this._dragIndicator) { this._dragIndicator.remove(); this._dragIndicator = undefined; }
        } else {
            if (this._dragIndicator == null) {
                this._dragIndicator = document.createElement('div');
                this._dragIndicator.className = 'o365-drag-indicator';
                container.append(this._dragIndicator);
            }
            const isBelow = positionIsBelow(pPosition);
            if (this._dataGridControl.isTable) {
                const containerRec = container.getBoundingClientRect();
                const rect = pRowEl.getBoundingClientRect();
                const position = isBelow
                    ? pRowEl.clientHeight + rect.top - containerRec.top
                    : rect.top - containerRec.top;
                this._dragIndicator.style.transform = `translateY(${position}px)`;
            } else {
                if (isBelow) {
                    const str = pRowEl.style.transform as string;
                    const translate = parseInt((str.match(/\d+/) || [])[0]); //parseInt(pRowEl.style.transform);
                    this._dragIndicator.style.transform = `translateY(${translate + pRowEl.clientHeight}px)`;
                } else {
                    this._dragIndicator.style.transform = pRowEl.style.transform;
                }
            }
        }
    }

    private _getRowFromEvent(pEvent: DragEvent): [DataItemModel?, HTMLElement?, number?, DragRowPosition?] {
        const row = (pEvent.target as HTMLElement)?.closest<HTMLElement>('.o365-body-row');
        if (row == null) { return []; }

        if (this._dataGridControl.isTable) {
            if (row.closest<HTMLElement>('[data-o365-container="N"]') != null) {
                return [];
            }
        }
        const index = +row.dataset.o365Rowindex!;

        const rect = row.getBoundingClientRect();
        let position: DragRowPosition = 'below';
        if (this._allowDragOver) {
        position = pEvent.clientY < rect.top +this._dragMargin
            ? 'above'
            : pEvent.clientY > (rect.top + rect.height - this._dragMargin)
                ? 'below'
                : 'over';
        } else {
            const midpoint = rect.top + (rect.height / 2);
            const bottomEdge = pEvent.clientY > midpoint;
            position = bottomEdge ? 'below' : 'above';
        }

        const data = this._getDataArray();

        return [data[index], row, index, position];
    }

    private _datetimeToNumber(pDate: string | number | Date) {
        if (pDate instanceof Date) {
            return +pDate;
        } else if (typeof pDate === 'string') {
            return + new Date(pDate);
        } else {
            return pDate;
        }
    }

    private _getDataArray(pSource = false) {
        if (this._dataGridControl.dataObject) {
            return pSource
                ? this._dataGridControl.dataObject.storage.data
                : this._dataGridControl.dataObject.data;
        } else {
            return pSource
                ? this._dataGridControl.props.data ?? []
                : this._dataGridControl.utils.processedData ?? [];
        }
    }

    private _shiftItem(pFromIndex: number, pToIndex: number) {
        if (this._dataGridControl.dataObject) {
            this._dataGridControl.dataObject!.reshiftItem(pFromIndex, pToIndex);
        } else if (this._dataGridControl.props.data) {
            arrayMove(this._dataGridControl.props.data, pFromIndex, pToIndex);
        }
    }

    private _getRowPredicate<T extends Record<string, any>>(pRow: T) {
        return (x: T) => x[this._uniqueField] == pRow[this._uniqueField];
    }

}

function arrayMove<T>(pArray: (T | undefined)[], pOldIndex: number, pNewIndex: number) {
    if (pNewIndex >= pArray.length) {
        let k = pNewIndex - pArray.length + 1;
        while (k--) {
            pArray.push(undefined);
        }
    }
    pArray.splice(pNewIndex, 0, pArray.splice(pOldIndex, 1)[0]);
}

function positionIsBelow(pPosition: DragRowPosition) {
    return pPosition != 'above';
}

export type RowDragData = {
    index: number,
    key: string,
};

export type DragRowPosition = 'above' | 'below' | 'over';